import React, { useEffect, useState } from "react";
import { Box, Popper, ClickAwayListener } from "@mui/material";
import { CONSTANT } from "../../constants/constants";
import DropdownButton from "../../components/Button/DropdownButton";
import { ISimpleDropdownOption, SimpleDropdown } from "../SimpleDropdown/SimpleDropdown";
import { setCurrentCommonParameter } from "../../parametersConstancy/platformParametersSlice";
import { setCurrentCommonParameterWrapper } from "../../parametersConstancy/parametersConstancyUtils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { shouldResetupDGMToDay, getDGMNameById, isDgmDisabledByFilters } from "./utils";
import { useLocation } from "react-router-dom";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { validateSelection } from "../DatePicker/datePickerValidator";

export const dgmOptions: ISimpleDropdownOption[] = [
  {
    id: CONSTANT.DATE_GROUP_MODES.HOUR.toString(),
    name: "Hourly",
  },
  {
    id: CONSTANT.DATE_GROUP_MODES.DAY.toString(),
    name: "Daily",
  },
  {
    id: CONSTANT.DATE_GROUP_MODES.MONTH.toString(),
    name: "Monthly",
  },
  {
    id: CONSTANT.DATE_GROUP_MODES.QUARTER.toString(),
    name: "Quarterly",
  },
  {
    id: CONSTANT.DATE_GROUP_MODES.AGGREGATED.toString(),
    name: "Aggregated data",
  },
];

const GroupingMenu: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [dgmOptionsWithDisability, setDgmOptionsWithDisability] = useState<ISimpleDropdownOption[]>(dgmOptions);
  const { partnersSelected } = useSelector((state: RootState) => state.role);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { dateGroupingMode, dateRange, customDateRange } = useSelector(
    (state: RootState) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters
  );
  const [title, setTitle] = useState<string>(`Group by ${getDGMNameById(dateGroupingMode)}`);
  const { currentPage } = useSelector(
    (state: RootState) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters
  );
  const { filters } = useSelector((state: RootState) => state.advancedReport);

  const location = useLocation();

  const range = [
    {
      startDate: customDateRange.customDateStart || new Date(),
      endDate: customDateRange.customDateEnd || new Date(),
    },
  ];

  useEffect(() => {
    setTitle(`Group by ${getDGMNameById(dateGroupingMode).toLowerCase()}`);
    setDgmOptionsWithDisability(
      dgmOptions.map((opt) => {
        let isDisabled = false;
        let isHidden = false;
        switch (opt.id) {
          case CONSTANT.DATE_GROUP_MODES.HOUR.toString(): {
            if (
              currentPage.id === CONSTANT.PAGES.OVERVIEW.id ||
              currentPage.id === CONSTANT.PAGES.VISITOR_RECOGNITION.id ||
              isDgmDisabledByFilters(filters)
            ) {
              isHidden = true;
              break;
            }
            if (
              dateRange.value === CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.value ||
              dateRange.value === CONSTANT.DATE_RANGE_TYPES.YTD.value ||
              dateRange.value === CONSTANT.DATE_RANGE_TYPES.ALL_TIME.value ||
              currentPage.id === CONSTANT.PAGES.OVERVIEW.id
            ) {
              isDisabled = true;
              break;
            }

            break;
          }
          case CONSTANT.DATE_GROUP_MODES.DAY.toString(): {
            break;
          }
          case CONSTANT.DATE_GROUP_MODES.MONTH.toString(): {
            const vrMonthlyDisabled = shouldResetupDGMToDay(filters, CONSTANT.DATE_GROUP_MODES.MONTH);
            if (
              dateRange.value === CONSTANT.DATE_RANGE_TYPES.YESTARDAY.value ||
              dateRange.value === CONSTANT.DATE_RANGE_TYPES.LAST_WEEK.value ||
              dateRange.value === CONSTANT.DATE_RANGE_TYPES.MTD.value ||
              isDgmDisabledByFilters(filters)
            ) {
              isDisabled = true;
              break;
            } else if (currentPage.id === CONSTANT.PAGES.VISITOR_RECOGNITION.id && vrMonthlyDisabled) {
              isDisabled = true;
            }
            if (dateRange.value === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) {
              isDisabled =
                !validateSelection(CONSTANT.DATE_GROUP_MODES.MONTH, range).isValid ||
                (currentPage.id === CONSTANT.PAGES.VISITOR_RECOGNITION.id && vrMonthlyDisabled);
            }
            break;
          }
          case CONSTANT.DATE_GROUP_MODES.QUARTER.toString(): {
            if (currentPage.id === CONSTANT.PAGES.VISITOR_RECOGNITION.id) isHidden = true;
            if (
              dateRange.value === CONSTANT.DATE_RANGE_TYPES.YESTARDAY.value ||
              dateRange.value === CONSTANT.DATE_RANGE_TYPES.LAST_WEEK.value ||
              dateRange.value === CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.value ||
              dateRange.value === CONSTANT.DATE_RANGE_TYPES.MTD.value ||
              isDgmDisabledByFilters(filters)
            ) {
              isDisabled = true;
              break;
            }
            if (dateRange.value === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) {
              isDisabled = !validateSelection(CONSTANT.DATE_GROUP_MODES.QUARTER, range).isValid;
            }
            break;
          }
          case CONSTANT.DATE_GROUP_MODES.AGGREGATED.toString(): {
            if (currentPage.id === CONSTANT.PAGES.VISITOR_RECOGNITION.id) isHidden = true;
            break;
          }

          default: {
          }
        }
        return { ...opt, isDisabled, isHidden };
      })
    );
  }, [dateRange, dateGroupingMode, currentPage]);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setOpen((prevOpen) => !prevOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleSelectDgm = (id: string) => {
    if (location.pathname !== CONSTANT.PAGES.PERFORMANCE_REPORT.path && +id === CONSTANT.DATE_GROUP_MODES.HOUR) {
      dispatch(
        setCurrentCommonParameter(
          setCurrentCommonParameterWrapper("dateGroupingMode", CONSTANT.DATE_GROUP_MODES.DAY)
        )
      );
    } else {
      dispatch(setCurrentCommonParameter(setCurrentCommonParameterWrapper("dateGroupingMode", +id)));
    }
    if (location.pathname === CONSTANT.PAGES.OVERVIEW.path) {
    }
    if (
      location.pathname === CONSTANT.PAGES.PERFORMANCE_REPORT.path ||
      location.pathname === CONSTANT.PAGES.VISITOR_RECOGNITION.path
    ) {
    }
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
      <ClickAwayListener
        onClickAway={() => {
          if (open) {
            setOpen(false);
            setAnchorEl(null);
          }
        }}
      >
        <div>
          <DropdownButton
            title={title}
            variant="filter"
            dropdownOpened={open}
            isDisabled={!(partnersSelected?.length > 0)}
            buttonCallback={handleButtonClick}
            clearIconCallback={() => {}}
            shouldBeSelected={true}
            base={CONSTANT.REPORTS.PARTNERS_CONFIG.base}
            selectedItemsLength={1}
            id="grouping-btn"
          />

          <Popper
            id="grouping-dropdown"
            open={open}
            anchorEl={anchorEl}
            disablePortal={false}
            placement="bottom-end"
            sx={{
              zIndex: 10110,
              overflow: "hidden",
              width: "250px",
              maxHeight: "calc(100vh - 20px)",
              marginTop: "8px !important",
            }}
            modifiers={[
              {
                name: "flip",
                enabled: true,
                options: {
                  altBoundary: true,
                  rootBoundary: "document",
                },
              },
            ]}
          >
            <SimpleDropdown
              options={dgmOptionsWithDisability}
              selectedItem={{ id: dateGroupingMode.toString(), name: "" }}
              onSelectItem={handleSelectDgm}
            />
          </Popper>
        </div>
      </ClickAwayListener>
    </Box>
  );
};

export default GroupingMenu;

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useRenderSelectedDate } from "../../overview/helpers/useRenderSelectedDate";
import useOpenFilters from "../advancedReport/helpers/useOpenFilters";
import { Box, useMediaQuery } from "@mui/material";
import auth from "../../../auth/auth-helper";
import { setParnterName } from "../../../role/roleSlice";
import { setReplaced } from "../../../report/reportSlice";
import {
  callsFilter,
  resetVRTData,
  setActiveFilters,
  visitortRecognitionCategoriesFilters,
} from "../advancedReport/advancedReportSlice";
import { activeBtn, sectionWrapper } from "../../overview/styles";
import LoaderWithProgress from "../../../components/LoaderWithProgress/LoaderWithProgress";
import WarningConnectionIssue from "../advancedReport/table/WarningConnectionIssue";
import ForbiddenResourceNotification from "../../../components/notifications/ForbiddenResourceNotification";
import { renderTableLoader } from "../advancedReport/advancedReport-helpers";
import SearchIcon from "../../../components/IconComponents/SearchIcon";
import Reminder from "../advancedReport/Reminder";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { getVRCategories, getVisitorRecognitionData } from "./visitorRecognitionFetches";
import VRTable from "./VRTable";
import VRWidgetsPanel, { chartTypes } from "./VRWidgetsPanel";
import { CONSTANT } from "../../../constants/constants";
import FiltersHandler from "../advancedReport/filtersBoard/FiltersHandler";
import { filterHandlerStl } from "../advancedReport/styles";
import { CategoryType, DateRangeType } from "../../../parametersConstancy/parametersConstancyTypes";
import { RootState } from "../../../store/store";
import { setCurrentCommonParameter } from "../../../parametersConstancy/platformParametersSlice";
import { setCurrentCommonParameterWrapper } from "../../../parametersConstancy/parametersConstancyUtils";
import { arePartnersEqual, insertAsteriskValues } from "../advancedReport/utils";
import DateRangePanel from "../../../components/DateRangePanel/DateRangePanel";
import { shouldResetupDGMToDay, getValueByType } from "../../../components/DateRangePanel/utils";

interface Props {}

const VisitorRecognitionPage: React.FC<Props> = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const navigate = useNavigate();
  const innerRef = useRef<HTMLDivElement>(null);
  const {
    vrData,
    filters,
    processing,
    status: advancedReportRequestStatus,
    timeSelection,
    error,
    vrDataSummary,
    lastFiltersLoadedType,
  } = useSelector((state: any) => state.advancedReport);
  let { filterBase } = useSelector((state: any) => state.report);
  const {
    partnersSelected,
  }: {
    partnersSelected: any[];
  } = useSelector((state: any) => state.role);
  const parametersIsFetched: boolean = useSelector((state: RootState) => state.platformParameters.isFetched);
  const dateGroupingMode = useSelector(
    (state: RootState) =>
      state.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateGroupingMode
  );
  const customDateRange = useSelector(
    (state: RootState) =>
      state.platformParameters.currentPlatformParameters.commonUserPlatformParameters.customDateRange ?? {
        customDateStart: null,
        customDateEnd: null,
      }
  );
  const dateRange = useSelector(
    (state: RootState) =>
      state.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateRange ??
      CONSTANT.DATE_RANGE_TYPES.LAST_WEEK
  );
  let { filters: filtersStored } = useSelector(
    (state: RootState) => state.platformParameters.currentPlatformParameters.performanceReportParameters
  );
  const token = useSelector((state: RootState) => state.users.loggedUser.token) ?? "";
  const timeGrouping = dateRange.value;
  const [renderSelectedDate] = useRenderSelectedDate(timeGrouping, customDateRange, timeSelection);
  let { open: openFiltersPopup } = useOpenFilters();

  const activeFilters: any = Object.fromEntries(
    Object.entries(filters)
      .filter(([key]) => visitortRecognitionCategoriesFilters.includes(key))
      .map(([key, values]: [string, any]) => [key, values.filter((unit: CategoryType) => unit.isSelected)])
  );

  const [clicked, setClicked] = useState(false);
  const initialRender = useRef(true);
  const matches = useMediaQuery("(min-width:1520px)");
  const jwt = auth.isAuthenticated();
  const previousPartnersSelected = useRef<any[]>([]);
  let chartType = "";
  if (activeFilters.actionTerminations.length > 0) {
    chartType = chartTypes.termination;
  } else if (activeFilters.platforms.length > 0) {
    chartType = chartTypes.platforms;
  } else if (activeFilters.actionTerminations.length === 0 && activeFilters.platforms.length === 0) {
    chartType = chartTypes.default;
  }

  useEffect(() => {
    dispatch(setReplaced(jwt.data.user.apt));
    if (
      dateGroupingMode !== CONSTANT.DATE_GROUP_MODES.DAY &&
      dateGroupingMode !== CONSTANT.DATE_GROUP_MODES.MONTH
    ) {
      dispatch(
        setCurrentCommonParameter(
          setCurrentCommonParameterWrapper("dateGroupingMode", CONSTANT.DATE_GROUP_MODES.DAY)
        )
      );
    }
    if (partnersSelected.length > 0) return;
    const abortController = new AbortController();
    if (auth.isAdminRole(jwt)) {
      if (auth.isExpired(jwt.data.accessToken)) {
        auth.clearStorage(() => navigate("/login"));
      }
      return;
    }
    let prntName: any = auth.getPartnersName();
    if (!!prntName.name) dispatch(setParnterName(prntName.name));

    dispatch(setReplaced(jwt.data.user.apt));
    return function cleanup() {
      abortController.abort();
    };
  }, []);

  const updateReports = (
    partnersSelected: any,
    parametersIsFetched: any,
    dateRange: any,
    customDateRange: any,
    dateGroupingMode: any,
    dispatch: any,
    signal: any
  ) => {
    if (partnersSelected.length > 0 && parametersIsFetched) {
      let activeFilterWithReplacedValues = { ...activeFilters };
      if (initialRender.current) {
        initialRender.current = false;
        activeFilterWithReplacedValues = insertAsteriskValues(filtersStored, activeFilters);
        if (
          activeFilterWithReplacedValues.countries.length > 0 &&
          activeFilterWithReplacedValues.sites.length > 0
        ) {
          activeFilterWithReplacedValues.countries = [];
          activeFilterWithReplacedValues.sites = [];
        }
        if (
          activeFilterWithReplacedValues.platforms.length > 0 &&
          activeFilterWithReplacedValues.actionTerminations.length > 0
        ) {
          activeFilterWithReplacedValues.platforms = [];
        }
      }
      getVRdata(activeFilterWithReplacedValues, dispatch, partnersSelected, signal);
    }
  };

  const abortControllerCat = new AbortController();
  const abortControllerRep = new AbortController();
  useEffect(() => {
    if (!arePartnersEqual(partnersSelected, previousPartnersSelected.current) && partnersSelected.length > 0) {
      getVRCategories(
        dispatch,
        partnersSelected.map((p: any) => p.dummy_id),
        visitortRecognitionCategoriesFilters,
        lastFiltersLoadedType,
        abortControllerCat.signal,
        token
      );
    }
    updateReports(
      partnersSelected,
      parametersIsFetched,
      dateRange,
      customDateRange,
      dateGroupingMode,
      dispatch,
      abortControllerRep.signal
    );
    previousPartnersSelected.current = partnersSelected;
    return function cleanup() {
      abortControllerRep.abort();
    };
  }, [parametersIsFetched, dateRange, customDateRange, dateGroupingMode, partnersSelected, dispatch]);

  const getVRdata = (
    activeFilters: any,
    dispatch: ThunkDispatch<any, any, any>,
    partnersSelected: any[],
    signal: AbortSignal
  ) => {
    let payload = {
      ...activeFilters,
    };
    if (timeGrouping) {
      if (activeFilters.sites.length > 0 && activeFilters.countries.length > 0) {
        payload.sites = [];
        payload.countries = [];
      }
      if (activeFilters.platforms.length > 0 && activeFilters.actionTerminations.length > 0) {
        payload.platforms = [];
        payload.actionTerminations = [];
      }
      if (payload.platforms.length === 0 && payload.calls.length === 0) {
        payload.calls = [callsFilter[0]];
      }
      if (payload.platforms.length !== 0) {
        payload.calls = [];
      }
      dispatch(resetVRTData({}));
      dispatch(setActiveFilters(payload));
      const shouldResetupDgm = shouldResetupDGMToDay(filters, dateGroupingMode);
      if (shouldResetupDgm) {
        dispatch(
          setCurrentCommonParameter(
            setCurrentCommonParameterWrapper("dateGroupingMode", CONSTANT.DATE_GROUP_MODES.DAY)
          )
        );
      } else {
        dispatch(
          getVisitorRecognitionData({
            requestData: {
              ...payload,
              timeGrouping: timeGrouping,
              customDateRange: customDateRange,
              partnersIds: partnersSelected.map((p) => p.dummy_id),
            },
            signal,
          })
        );
      }
    }
  };

  const isActiveButton = (type: number) => {
    return timeGrouping === type ? activeBtn : null;
  };

  const handleDateRangeButtons = (type: string) => {
    const rangeType: DateRangeType = { type: type, value: getValueByType(type) as any as number };
    dispatch(setCurrentCommonParameter(setCurrentCommonParameterWrapper("dateRange", rangeType)));
  };

  let canRequest = !(partnersSelected.length > 0) && auth.isAdminRole(jwt);

  if (processing) {
    return (
      <LoaderWithProgress
        sx={{
          display: "flex",
          width: "100%",
          minHeight: "75vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );
  }

  if (advancedReportRequestStatus === "failed" && error === "server can not get report") {
    return <WarningConnectionIssue activeFilters={activeFilters} />;
  }
  if (advancedReportRequestStatus === 403) return <ForbiddenResourceNotification />;

  return (
    <div>
      <DateRangePanel
        renderSelectedDate={renderSelectedDate}
        isActiveButton={isActiveButton}
        handleDateRangeButtons={handleDateRangeButtons}
        isDisabled={canRequest}
        dateGroupingMode={dateGroupingMode}
        filterBase={filterBase}
        active_range_date={timeGrouping}
        matches={matches}
        hourPicker={null}
      />

      <Box
        id="mainTableBlockWrapper"
        sx={{
          ...sectionWrapper,
          marginTop: "1.5rem",
          marginBottom: "1rem",
          marginRight: "1rem",
        }}
      >
        {/* Widgets Panel - charts section */}
        {vrDataSummary?.categorySummary && vrData.length > 0 && (
          // ((vrDataSummary?.categorySummary[CONSTANT.REPORTS.FILTERS_KEYS.ACTION_TERMINATIONS.base] &&
          //   Object.values(vrDataSummary?.categorySummary[CONSTANT.REPORTS.FILTERS_KEYS.ACTION_TERMINATIONS.base])
          //     ?.length > 0) ||
          //   (vrDataSummary?.categorySummary[CONSTANT.REPORTS.FILTERS_KEYS.PLATFORMS.base] &&
          //     Object.values(vrDataSummary?.categorySummary[CONSTANT.REPORTS.FILTERS_KEYS.PLATFORMS.base])?.length >
          //       0)) &&
          <VRWidgetsPanel chartType={chartType} />
        )}
        <Box sx={filterHandlerStl}>
          <FiltersHandler />
        </Box>
        {/* TABLE Section */}
        {vrData.length > 0 ? (
          <VRTable />
        ) : (
          renderTableLoader(
            advancedReportRequestStatus === "succeeded"
              ? `No reports for selected parameters... `
              : "Consider adjusting the filters to view the reports",
            `${
              advancedReportRequestStatus === CONSTANT.HTTP_STATUSES.PAYLOAD_TOO_LARGE
                ? error
                : "Please, use filters to view reports... "
            }`,
            <SearchIcon />
          )
        )}
      </Box>
      <Reminder elemRef={innerRef} stats={vrData.length || openFiltersPopup || clicked} />
    </div>
  );
};

export default VisitorRecognitionPage;

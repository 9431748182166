import React from "react";
import LogoutPopup from "../LogoutPopup/LogoutPopup";
import { CONSTANT } from "../../constants/constants";
import { Divider, Box, useTheme } from "@mui/material";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoIcon from "@mui/icons-material/Info";
import LogoutIcon from "@mui/icons-material/Logout";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import { accountsApi } from "../../accounts/accountsApi";
import { useDispatch, useSelector } from "react-redux";
import { resetReport } from "../../report/reportSlice";
import { resetAdvancedReport } from "../../core/performanceReports/advancedReport/advancedReportSlice";
import { setPartnersList, updateIsSelectAllSelected } from "../../role/roleSlice";
import { RootState } from "../../store/store";

const stl = {
  card: {
    maxWidth: 600,
    margin: "auto",
    marginTop: "5px",
    paddingBottom: "2px",
    color: "secondary.main",
    fontWeight: 700,
  },
  cardItem: {
    cursor: "pointer",
    padding: "10px",
    "&:hover": {
      background: "#f7f9fa",
    },
    display: "flex", // Use flexbox to center items horizontally
    alignItems: "center", // Center items vertically
    // justifyContent: 'center',   // Center items horizontally
    gap: "10px", // Adjust the gap between icon and text
  },
};

export default function SettingsWrapper(props: {
  openProfileInfoPopover: React.MouseEventHandler<HTMLDivElement> | undefined;
  openReportSettingsPopover: React.MouseEventHandler<HTMLDivElement> | undefined;
  openVersionInfoPopover: React.MouseEventHandler<HTMLDivElement> | undefined;
}) {
  const dispatch = useDispatch();
  const { partnersList, isReportingPartnersSelected } = useSelector((state: RootState) => state.role);
  let renderPartnersList = partnersList;
  if (isReportingPartnersSelected) {
    renderPartnersList = partnersList.filter((parter: any) => parter.isReporting);
  }

  const handleResetSettings = () => {
    dispatch(resetReport());
    dispatch(resetAdvancedReport({ keepFilters: false }));
    const partnerListSelected = isReportingPartnersSelected ? renderPartnersList : partnersList;
    dispatch(updateIsSelectAllSelected(true));
    dispatch(setPartnersList(partnerListSelected));
    // window.location.reload();
  };

  return (
    <Box sx={stl.card}>
      <Box id="profile-btn" sx={stl.cardItem} onClick={props.openProfileInfoPopover}>
        <PermIdentityIcon /> Profile
      </Box>
      <Box id="report-settings-btn" sx={stl.cardItem} onClick={props.openReportSettingsPopover}>
        <SettingsIcon /> Report Settings
      </Box>
      <Box id="reset-selected-parameters-btn" sx={stl.cardItem} onClick={handleResetSettings}>
        {" "}
        <RotateLeftIcon /> Reset selected parameters
      </Box>
      <Box
        id="service-status-btn"
        sx={stl.cardItem}
        onClick={() => window.open("https://status.intentiq.com/", "_blank", "noreferrer")}
      >
        <EqualizerIcon sx={{ transform: "rotate(90deg)" }} /> Service status
      </Box>
      <Divider sx={{ margin: "10px 0" }} />
      <Box id="about-btn" sx={stl.cardItem} onClick={props.openVersionInfoPopover}>
        <InfoIcon style={{ color: "white", background: "black", borderRadius: "50px" }} /> About
      </Box>
      <Box id="logout-btn" sx={stl.cardItem}>
        <LogoutIcon />
        <LogoutPopup renderButton={false} showConfirmPopup={false} />
      </Box>
    </Box>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import { getStats } from "./getStats";
import { getVisitorRecognitionStats } from "./getVisitorRecognitionStats";
import { CONSTANT } from "../../../constants/constants";
import { processStatsToAreaChart } from "./utils";
import { aggregateValuesToSummaryRow } from "./table/table-helpers";
import { getVisitorRecognitionData } from "../visitorRecognition/visitorRecognitionFetches";
import { applyPctAndCurrencyToSummaryChart } from "../../../summary/summaryThunks/applyPctAndCurrencyToSummaryChart";

const DEFAULT_SITE_FILTRATION_DESELECTION = CONSTANT.defaultPctSiteFiltrationDeselection;
export const performanceReportCategoriesFilters = Object.values(CONSTANT.REPORTS.FILTERS_KEYS)
  .filter((filter) => filter.pagesIds && filter.pagesIds.includes(CONSTANT.PAGES.PERFORMANCE_REPORT.id))
  .map((filter) => filter.base);

export const visitortRecognitionCategoriesFilters = Object.values(CONSTANT.REPORTS.FILTERS_KEYS)
  .filter((filter) => filter.pagesIds && filter.pagesIds.includes(CONSTANT.PAGES.VISITOR_RECOGNITION.id))
  .map((filter) => filter.base);

export const callsFilter = [
  { id: 0, name: "All", isSelected: true },
  { id: 1, name: "API Calls", isSelected: false },
  { id: 2, name: "Internal Calls", isSelected: false },
];
export const emptyFilters = {
  currencies: [],
  pct: [],
  actionTerminations: [],
  platforms: [],
  biddercodes: [],
  jsVersions: [],
  sites: [],
  browsers: [],
  deviceTypes: [],
  countries: [],
  calls: callsFilter,
  integrations: [],
  pbVersions: [],
};
const initialState = {
  test: null,
  stats: [],
  vrData: [],
  vrDataSummary: {},
  statsRawData: [],
  timeSelection: 72,
  filters: {
    ...emptyFilters,
  },
  status: "idle",
  processing: false,
  error: null,
  filtersIsLoading: {
    currencies: true,
    pct: true,
    actionTerminations: true,
    platforms: true,
    biddercodes: true,
    jsVersions: true,
    sites: true,
    browsers: true,
    deviceTypes: true,
    countries: true,
    partners: false,
    integrations: true,
    pbVersions: true,
  },
  lastFiltersLoadedType: "",
  pctSiteReportsDeselection: DEFAULT_SITE_FILTRATION_DESELECTION,
  hiddenColumns: {},
  widgetChart: {
    records: { summary: {}, perPartner: {} },
    currenciesToDisplay: {},
    activeAreaChartTypes: [CONSTANT.ADVANCED_REPORT.AREA_CHART_TYPES.REVENUE.id],
    agregationTypeId: CONSTANT.ADVANCED_REPORT.AGREGATION_DATA_CHART_TYPES.SUMMARY.id,
  },
  healthStatusRateRecords: [],
  healthStatusRateTotalRecordsBySelection: null,
  combinedDataMode: 1,
  rawCurrency: [],
  rawPct: [],
  uniqPairs: [],
  apiFiltersChanged: false,
  timeInputText: "",
  getStatsBody: "",
  summaryCurrency: "",
  sortModelGen: {
    model: [{ field: "submitted_date", sort: "desc" }],
    selectedCell: null,
    cellName: "",
  },
  filterModelGen: {
    model: { items: [] },
    terms: "",
  },
  getStatsStatus: null,
};

export const advancedReportSlice = createSlice({
  name: "advancedReport",
  initialState,
  reducers: {
    setAdvancedReportEntryParametersConstancy(state, action) {
      if (
        action.payload?.performanceReportParameters &&
        Object.keys(action.payload?.performanceReportParameters).length
      ) {
        const { performanceReportParameters } = action.payload;
        if (performanceReportParameters.filters.timeGrouping) {
          delete performanceReportParameters.filters.timeGrouping;
        }
        state.filters = { ...state.filters, ...performanceReportParameters.filters };
        if (
          performanceReportParameters.filters?.calls?.length === undefined ||
          performanceReportParameters.filters?.calls?.length <= 1 ||
          performanceReportParameters.filters?.calls[0]?.id === CONSTANT.REPORTS.SELECTED_ALL_OBJECT.id
        ) {
          state.filters.calls = callsFilter;
        }
        state.widgetChart.activeAreaChartTypes = performanceReportParameters.activeAreaChartTypes ?? [];
        state.widgetChart.currenciesToDisplay = performanceReportParameters.areaChartCurrenciesToDisplay;
        state.widgetChart.agregationTypeId =
          performanceReportParameters.agregationTypeId ||
          CONSTANT.ADVANCED_REPORT.AGREGATION_DATA_CHART_TYPES.SUMMARY.id;

        if (performanceReportParameters.columnVisibilityModel) {
          state.hiddenColumns = performanceReportParameters.columnVisibilityModel;
        }
      }
    },
    switchSummaryCurrency(state, action) {
      const iCurrent = state.rawCurrency.indexOf(state.summaryCurrency);
      let iNew;
      if (action.payload === "up") iNew = iCurrent !== state.rawCurrency.length - 1 ? iCurrent + 1 : 0;
      if (action.payload === "down") iNew = iCurrent !== 0 ? iCurrent - 1 : state.rawCurrency.length - 1;
      state.summaryCurrency = state.rawCurrency[iNew];
    },
    setFilter(state, action) {
      delete action.payload.reactAppVersion;
      delete action.payload.timeGrouping;
      const name = Object.keys(action.payload)[0];
      const values = Object.values(action.payload)[0].map((item) => {
        const id = item.id;
        let convertedId = typeof id === "string" ? Number(id) : id;
        return { ...item, id: !isNaN(convertedId) ? convertedId : id };
      });
      if (state.filters[name]?.length > 0) {
        if (state.filters[name][0].id === CONSTANT.REPORTS.SELECTED_ALL_OBJECT.id) {
          values.forEach((item) => (item.isSelected = true));
        } else {
          const currentActiveFilterStateIds = state.filters[name]
            .filter((item) => item.isSelected)
            .map((item) => item.id);

          values.forEach((item) => {
            if (currentActiveFilterStateIds.includes(item.id)) {
              item.isSelected = true;
            }
          });
        }
      }
      state.filters[name] = values;
      state.filtersIsLoading[name] = false;
    },
    setLastFiltersLoadedType(state, action) {
      state.lastFiltersLoadedType = action.payload;
    },
    setFilterVR(state, action) {
      delete action.payload.reactAppVersion;
      delete action.payload.timeGrouping;

      const name = Object.keys(action.payload)[0];
      const values = Object.values(action.payload)[0].map((item) => {
        const id = item.id;
        let convertedId = typeof id === "string" ? Number(id) : id;
        return { ...item, id: !isNaN(convertedId) ? convertedId : id };
      });
      if (state.filters[name]?.length > 0) {
        if (state.filters[name][0].id === CONSTANT.REPORTS.SELECTED_ALL_OBJECT.id) {
          values.forEach((item) => (item.isSelected = true));
        } else {
          const currentActiveFilterStateIds = state.filters[name]
            .filter((item) => item.isSelected)
            .map((item) => item.id);
          values.forEach((item) => {
            if (currentActiveFilterStateIds.includes(item.id)) {
              item.isSelected = true;
            }
          });
        }
      }
      state.filters[name] = values;
      state.filtersIsLoading[name] = false;
    },
    setLastLoadedFiltersType(state, action) {
      state.lastFiltersLoadedType = action.payload;
    },
    resetAdvancedReport(state, action) {
      return {
        ...initialState,
        filters: action.payload.keepFilters
          ? { ...state.filters }
          : {
              ...emptyFilters,
              calls: callsFilter,
            },
      };
    },
    setActiveFilters(state, action) {
      if (!action.payload) {
        state.filters = emptyFilters;
      } else {
        delete action.payload.timeGrouping;
        Object.entries(action.payload).forEach(([key, values]) => {
          if (values.length > 0 && values[0].id === CONSTANT.REPORTS.SELECTED_ALL_OBJECT.id) {
            if (state.filtersIsLoading[key]) {
              state.filters[key] = values;
            } else {
              const updatedValues = state.filters[key].map((item) => {
                return { ...item, isSelected: true };
              });
              state.filters[key] = updatedValues;
            }
          } else {
            const valuesIds = values.map((value) => value.id);
            const updatedValues = state.filters[key].map((item) => {
              let isSelected = false;
              if (valuesIds.includes(item.id)) {
                isSelected = true;
              }
              return { ...item, isSelected };
            });
            state.filters[key] = updatedValues;
          }
        });
      }
    },
    setActiveFiltersByApplyGroup(state, action) {
      if (!action.payload) {
        state.filters = emptyFilters;
      } else {
        Object.entries(action.payload).forEach(([key, values]) => {
          state.filters[key] = values;
          state.filtersIsLoading[key] = true;
        });
      }
    },
    setAsteriskToActiveFilters(state, action) {
      Object.entries(action.payload).forEach(([key, values]) => {
        if (values.length > 0 && values[0].id === CONSTANT.REPORTS.SELECTED_ALL_OBJECT.id) {
          state.filters[key] = values;
        }
      });
    },
    setFiltersIsLoading(state, action) {
      state.filtersIsLoading[action.payload.name] = action.payload.value;
    },
    setTimeSelection(state, action) {
      state.timeSelection = action.payload;
    },
    setPctSiteReportsDeselection(state, action) {
      state.pctSiteReportsDeselection = action.payload;
    },
    resetMainTableData(state, action) {
      state.stats = [];
      state.widgetChart.records = { summary: {}, perPartner: {} };
    },
    resetVRTData(state, action) {
      state.vrData = [];
      state.vrDataSummary = {};
    },
    resetTimeRangeAndSelectionBase(state, action) {
      state.filters.sites = [];
      state.filters.biddercodes = [];
      state.filters.jsVersions = [];
      state.timeSelection = CONSTANT.DATE_RANGE_TYPES.LAST_WEEK.value;
      state.filters.vrSite = [];
      state.filters.biddercodes = [];
      state.filters.jsVersions = [];
      state.filters.pct = [];
      state.filters.currencies = [];
    },
    resetTimeRangeOnly(state, action) {
      state.timeSelection = CONSTANT.DATE_RANGE_TYPES.LAST_WEEK.value;
    },
    resetSelectionBaseRawData(state, action) {
      state.statsRawData = [];
      state.filters.deviceTypes = [];
      state.filters.countries = [];
      state.filters.actionTerminations = [];
      state.filters.browsers = [];
      state.filters.pct = [];
      state.filters.currencies = [];
    },
    setHiddenColumns(state, action) {
      state.hiddenColumns = action.payload;
    },
    setActiveAreaChartType(state, action) {},
    setActiveAreaChartTypes(state, action) {
      state.widgetChart.activeAreaChartTypes = action.payload;
    },
    setCombinedDataMode(state, action) {
      state.combinedDataMode = action.payload;
    },
    resetStatusesAndErrors(state, action) {
      state.stats = [];
      state.widgetChart.records = { summary: {}, perPartner: {} };
      state.status = "idle";
      state.processing = false;
      state.error = null;
      state.statusHealthStatusRate = "OK";
      state.processingHealthStatusRate = false;
      state.vrData = [];
    },
    toggleWidgetChartCurrencyToDisplay(state, action) {
      state.widgetChart.currenciesToDisplay = action.payload;
      // !state.widgetChart.currenciesToDisplay[action.payload];
    },
    setAgregationTypeId(state, action) {
      state.widgetChart.agregationTypeId = action.payload;
    },
    setGetStatsBody(state, action) {
      state.getStatsBody = action.payload;
    },
    setFilterModelGen(state, action) {
      state.filterModelGen = action.payload;
    },
    setSortModelGen(state, action) {
      state.sortModelGen = action.payload;
    },
    setGetStatsStatus(state, action) {
      state.getStatsStatus = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getStats.pending, (state, action) => {
        state.status = "loading";
        state.processing = true;
      })
      .addCase(getStats.fulfilled, (state, action) => {
        if (action.payload.result?.data.length) {
          let data = action.payload.result.data;
          // Add columns for future summary aggregation
          data.forEach((r) => {
            r.total_rows2Norm = (r.total_rows2 * r.pct) / (100 - r.pct);
            r.total_cpm2Norm = (r.total_cpm2 * r.pct) / (100 - r.pct);
          });

          let stats = data;
          let template;

          let {
            pctModes,
            currencyModes,
            uniqPairs,
            reportSelectedPct,
            reportSelectedCurrency,
            dateGroupingMode,
            activeRangeDate,
            customDateRange,
          } = action.payload;
          state.uniqPairs = uniqPairs;

          // if (pctModes.length > 1 || currencyModes.length > 1)
          //   stats = stats.filter((i) => i.pct === +reportSelectedPct && i.currency === reportSelectedCurrency);
          state.stats = stats;
          state.statsRawData = data;

          // pre-allocate the array with the target size and set every element using the index
          const rowsResult = new Array(stats.length);

          for (let i = 0; i < stats.length; ++i) {
            rowsResult[i] = { id: i + 1, ...stats[i] };
          }
          state.rows2 = rowsResult;
          // state.summaryRows = aggregateValuesToSummaryRow(rowsResult, reportSelectedPct, af, "source toolkit");
          state.rawCurrency = action.payload.currencyModes;
          if (state.rawCurrency.includes("USD")) {
            state.summaryCurrency = "USD";
          } else state.summaryCurrency = state.rawCurrency[0];

          state.rawPct = action.payload.pctModes;

          const activeFilters = Object.fromEntries(
            Object.entries(state.filters).map(([key, values]) => [key, values.filter((unit) => unit.isSelected)])
          );

          let summaryRows = aggregateValuesToSummaryRow(
            rowsResult,
            activeFilters,
            "source toolkit",
            action.payload.pctModes.map((c) => {
              return {
                pct: c,
              };
            }),
            [],
            action.payload.currencyModes.map((c) => {
              return {
                currency: c,
              };
            }),
            []
          );
          state.mainTableUtils = {
            rows2: rowsResult,
            summaryRows: summaryRows,
          };
          state.rawCurrency.forEach((c) => {
            if (!state.widgetChart?.currenciesToDisplay) {
              state.widgetChart.currenciesToDisplay = {};
            }
            if (state.widgetChart.currenciesToDisplay[c] === undefined) {
              state.widgetChart.currenciesToDisplay[c] = true;
            }
          });
          state.widgetChart.records = processStatsToAreaChart(
            state.stats,
            activeRangeDate,
            state.rawCurrency,
            customDateRange?.customDateStart,
            customDateRange?.customDateEnd,
            dateGroupingMode,
            state.timeSelection
          );
          // generate template and prepare data for area chart
          // let { reportDateStart, chartLastDate } = generateDateToAreaChart(state);
          // if (state.activeFilters.timeGrouping == -2) {
          //   template = prepareTemplateHours(reportDateStart.startDate, chartLastDate);
          // } else {

          //   if (dateGroupingMode == MONTH) {
          //     template = prepareTemplateMonthlyGrouped(reportDateStart.startDate, chartLastDate);
          //   } else {
          //     template = prepareTemplate(reportDateStart.startDate, chartLastDate);
          //   }
          // }

          // state.widgetChart.records = convertToWidgetAreaChartData(template, stats, reportSelectedPct, state.activeFilters.timeGrouping);
        } else {
          if (!action.payload.useCache) {
            state.stats = [];
            state.widgetChart.records = { summary: {}, perPartner: {} };
            state.healthStatusRateRecords = [];
            state.healthStatusRateTotalRecordsBySelection = null;
          }
        }

        state.processing = false;
        state.status = "succeeded";
      })
      .addCase(getStats.rejected, (state, action) => {
        if (action.payload === "report not found") {
          state.status = "not_found";
          state.error = action.payload;
        } else if (action.payload === "forbidden_resource") {
          state.status = 403;
          state.error = action.payload;
          state.processing = false;
        } else {
          state.processing = false;
          state.status = "failed";
          state.error = action.payload;
          // state.error = action.error.message;
        }
      });

    builder
      .addCase(getVisitorRecognitionStats.pending, (state, action) => {
        state.statusHealthStatusRate = "loading";
        state.processingHealthStatusRate = true;
      })
      .addCase(getVisitorRecognitionStats.fulfilled, (state, action) => {
        if (action.payload?.result) {
          state.healthStatusRateRecords = action.payload.result.data;
          state.healthStatusRateTotalRecordsBySelection = action.payload.result.totalRecordBySelection;
        }
        state.statusHealthStatusRate = "OK";
        state.processingHealthStatusRate = false;
      })
      .addCase(getVisitorRecognitionStats.rejected, (state, action) => {
        if (action.payload === "report not found") {
          state.statusHealthStatusRate = "not_found";
          state.error = action.payload;
        } else {
          state.processingHealthStatusRate = false;
          state.statusHealthStatusRate = "failed";
          state.error = action.payload;
          // state.error = action.error.message;
        }
      })
      .addCase(getVisitorRecognitionData.pending, (state, action) => {
        state.statusHealthStatusRate = "loading";
        state.processingHealthStatusRate = true;
        state.status = "loading";
        state.processing = true;
      })
      .addCase(getVisitorRecognitionData.fulfilled, (state, action) => {
        const vrDataWithIds = action.payload?.vrData?.map((row, index) => ({
          ...row,
          id: index + 1, // Or generate unique IDs using a library like uuid
        }));
        state.vrData = vrDataWithIds;
        state.vrDataSummary = action.payload?.summary;
        state.statusHealthStatusRate = "OK";
        state.processingHealthStatusRate = false;
        state.processing = false;
        state.status = "succeeded";
      })
      .addCase(getVisitorRecognitionData.rejected, (state, action) => {
        const error = action.payload;
        state.processing = false;
        if (error) {
          state.error = error.message;
          state.vrData = [];
          if (error.message === "FORBIDDEN") {
            state.status = "failed";
            state.error = "forbidden resource";
          }
          if (error.message === "TIMEOUT") {
            state.status = "failed";
            state.error = "server can not get report";
          }
          if (error.options) {
            state.processing = false;
            state.status = error.options.status;
            state.error = error.options.message;
            // state.error = action.error.message;
          }
        }
      })
      .addCase(applyPctAndCurrencyToSummaryChart.fulfilled, (state, action) => {
        if (!action.payload.reportSelectedPct) {
          state.filters.currencies = [];
          state.filters.pct = [];
        } else {
          state.filters.currencies = [
            {
              id: action.payload.reportSelectedCurrency,
              name: action.payload.reportSelectedCurrency,
              isSelected: true,
            },
          ];
          state.filters.pct = [
            { id: action.payload.reportSelectedPct, name: action.payload.reportSelectedPct, isSelected: true },
          ];
        }
      });
  },
});

export const {
  setAdvancedReportEntryParametersConstancy,
  setFilter,
  setFilterVR,
  setActiveFilters,
  setActiveFiltersByApplyGroup,
  setAsteriskToActiveFilters,
  resetMainTableData,
  setTimeSelection,
  resetTimeRangeAndSelectionBase,
  setFiltersIsLoading,
  setPctSiteReportsDeselection,
  setHiddenColumns,
  setActiveAreaChartTypes,
  setActiveAreaChartType,
  resetSelectionBaseRawData,
  resetTimeRangeOnly,
  resetStatusesAndErrors,
  setCombinedDataMode,
  resetAdvancedReport,
  filterByCurrencyAndPct,
  toggleWidgetChartCurrencyToDisplay,
  setAgregationTypeId,
  setGetStatsBody,
  switchSummaryCurrency,
  setLastLoadedFiltersType,
  setLastFiltersLoadedType,
  resetVRTData,
  setFilterModelGen,
  setSortModelGen,
  setGetStatsStatus,
} = advancedReportSlice.actions;

export default advancedReportSlice.reducer;

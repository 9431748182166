import { daysAgoSinceStartDate, getMTD } from "../utils";
import CashIcon from "../components/IconComponents/CashIcon";
import PercentIcon from "../components/IconComponents/PercentIcon";

export const CONSTANT = {
  REACT_APP_VERSION_MAINTENANCE: -7,
  USER_AVATAR_DOMAIN: "https://be-avatars.s3.eu-west-1.amazonaws.com/",
  defaultAvatarUrl: "https://be-avatars.s3.eu-west-1.amazonaws.com/default/Avatar_upd-default.svg",
  avatarUrlS3BucketPrefix: "default/Avatar_upd",
  path: {
    host: process.env.REACT_APP_BACKEND_ADDRESS,
    fullpath:
      process.env.REACT_APP_MODE === "development"
        ? "http://localhost:8080"
        : process.env.REACT_APP_BACKEND_ADDRESS,
  },
  //TODO: URL should not be hardcoded
  ids: {},
  IIQ_: -7, //FIXME Change to roles solution
  sessionIdle: 32400000,
  // KEEP_ALIVE_TIMEOUT: 7900000000, //91day
  defaultPctSiteFiltrationDeselection: 10,
  MAX_ROWS_PER_PAGE: 50,
  IIQ_ACCOUNT_ID: 0,
  COMBINED_STRING_KEY: "._.",
  COMBINED_STRING_KEY2: "||",

  SSE_EVENT_TYPES: {
    NEW_NOTIFICATION: "newNotification",
    ACCOUNT_UPDATED: "accountUpdated",
    HEARTBEAT: "heartbeat",
    SET_ON_MAINTENANCE: "setOnMainenance",
    REFETCH_USER_METADATA: "refetchUserMetadata",
    REFETCH_SESSIONS: "refetchSessions",
  },
  FILTER_ITEMS_TRESHOLD: 1500,

  OVERFLOW_UNREAD_MESSAGES: 10,

  HTTP_STATUSES: {
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    PAYLOAD_TOO_LARGE: 413,
    INTERNAL_SERVER_ERROR: 500,
    SERVICE_UNAVAILABLE: 503,
  },

  DEFAULT_TEMPLATE_PLATFORM_PARAMETRS: {
    id: -1,
    name: "Default",
    description:
      "This default template using when user doesnt save current platform parameters to a particular template",
  },

  DATE_GROUP_MODES: {
    DAY: 1,
    MONTH: 2,
    QUARTER: 3,
    AGGREGATED: 4,
    HOUR: 5,
  },

  DATE_RANGE_TYPES: {
    YESTARDAY: { type: "yesterday", value: 1, title: "Yesterday" },
    LAST_WEEK: { type: "lastWeek", value: 7, title: "Last Week" },
    LAST_MONTH: { type: "lastMonth", value: -30, title: "Last Month" },
    MTD: { type: "mtd", value: getMTD(), title: "MTD" },
    YTD: { type: "ytd", value: daysAgoSinceStartDate(), title: "YTD" },
    CUSTOM: { type: "custom", value: -1, title: "Custom" },
    ALL_TIME: { type: "allTime", value: -10, title: "All time" },
  },

  PAGES: {
    ROOT: { id: -11, name: "Root", path: "/" },
    LOGIN: { id: -1, name: "Login", path: "/login" },
    OVERVIEW: { id: 1, name: "Overview", path: "/overview" },
    PERFORMANCE_REPORT: { id: 2, name: "Performance Report", path: "/performance-report" },
    VISITOR_RECOGNITION: { id: 3, name: "Visitor Recognition", path: "/visitor-recognition" },
    TREND_ANALYSER: { id: 3, name: "Trend Analyser", path: "/trend-analyser" },
    ACCOUNTS: { id: 4, name: "Accounts", path: "/accounts" },
    ACCOUNTS_USERS: { id: 44, name: "Accounts", path: "/accounts/users" },
    USERS: { id: 41, name: "Users", path: "/users" },
    ROLES: { id: 42, name: "Roles", path: "/roles_management" },
    USER_DETAILS: { id: 43, name: "User Details", path: "/accounts/user_details" },
    ANALYTICS: { id: 44, name: "Users Analytics", path: "/analytics" },
    BILLING: { id: 5, name: "Billing", path: "/billing" },
    NOTIFICATIONS: { id: 6, name: "Notifications", path: "/notifications" },
    SUPPLY: { id: 7, name: "Supply Management", path: "/supply-management" },
    INTEGRATION: { id: 8, name: "Integration", path: "/integration" },
    CONFIGURATIONS: { id: 9, name: "Configurations", path: "/configuration" },
  },

  MONTHS: {
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
  },

  OVERVIEW: {
    CHART_BASES: {
      SUMMARY: "summary",
      CLIENT_TYPE: "client_type",
      BIDDERCODE: "biddercode",
      SITE_ID: "site_id",
      PLACEMENT_ID: "placement_id",
    },
    CHART_TYPES: {
      SUMMARY: 0,
      REVENUE_LIFT: 1,
      CPM_LIFT: 2,
      FILL_RATE_LIFT: 3,
    },
    CHART_TYPE_NAMES: {
      SUMMARY: "summary",
      REVENUE_LIFT: "revenueLift",
      CPM_LIFT: 2,
      FILL_RATE: "fillRate",
    },
    GROUPE_TYPES: {
      LIFT: "lift_type",
      AB: "ab_group_type",
    },
  },

  MODULE: {
    DASHBOARD_VIEW: 103,
    DASHBOARD_ADMIN: 104,
    BILLING_VIEW: 105,
    BILLING_ADMIN: 106,
    RMP_VIEW: 107,
    RMP_ADMIN: 108,
    BIDDERS_CONFIGURATION_VIEW: 111,
    BIDDERS_CONFIGURATION_ADMIN: 112,
  },

  MODULES_PAGES: {
    ROOT: { path: "/", modules: "/" },
    OVERVIEW: { path: "/overview", modules: [103, 104] },
    PERFORMANCE_REPORT: { path: "/performance-report", modules: [103, 104] },
    VISITOR_RECOGNITION: { path: "/visitor-recognition", modules: [103, 104] },
    TREND_ANALYSER: { path: "/trend-analyser", modules: [103, 104] },
    ACCOUNTS: { path: "/accounts", modules: [107, 108] },
    USERS: { path: "/users", modules: [107, 108] },
    ANALYTICS: { path: "/analytics", modules: [107, 108] },
    ROLES: { path: "/roles_management", modules: [107, 108] },
    BILLING: { path: "/billing", modules: [105, 106] },
    SUPPLY: { path: "/supply-management", modules: [107, 108] },
    INTEGRATION: { path: "/integration", modules: [107, 108] },
    BIDDERS_CONFIGURATION: { path: "/configuration", modules: [111, 112] },
  },

  localStorage: {
    beAppSettings: "be_app_settings",
    jwt: "jwt",
    buildVerssion: "build",
  },

  REPORTS: {
    FILTERS_KEYS: {
      PCT: { base: "pct", title: "AB Testing %", pagesIds: [2], isTableColumn: true },
      CURRENCIES: { base: "currencies", title: "Currency", pagesIds: [2], isTableColumn: true },
      ACTION_TERMINATIONS: {
        base: "actionTerminations",
        title: "Termination Cause",
        pagesIds: [2, 3],
        isTableColumn: true,
      },
      PLATFORMS: { base: "platforms", title: "Platform", pagesIds: [3], isTableColumn: true },
      JS_VERSIONS: { base: "jsVersions", title: "JS Version", pagesIds: [2, 3], isTableColumn: true },
      BIDDERS: { base: "biddercodes", title: "Bidder", pagesIds: [2], isTableColumn: true },
      SITES: { base: "sites", title: "Site", pagesIds: [2, 3], isTableColumn: true },
      DEVICE_TYPES: { base: "deviceTypes", title: "Device", pagesIds: [2, 3], isTableColumn: true },
      COUNTRIES: { base: "countries", title: "Country", pagesIds: [2, 3], isTableColumn: true },
      BROWSERS: { base: "browsers", title: "Browser", pagesIds: [2], isTableColumn: true },
      CALLS: { base: "calls", title: "Calls", pagesIds: [3], isTableColumn: false },
      INTEGRATIONS: { base: "integrations", title: "Integrations", pagesIds: [2, 3], isTableColumn: true },
      PB_VERSIONS: { base: "pbVersions", title: "Prebid", pagesIds: [2], isTableColumn: true },
    },
    PARTNERS: { base: "partners", title: "Partners", pagesIds: [1, 2, 3], isTableColumn: true },
    PARTNERS_CONFIG: { base: "partnersConfig", title: "Partners", pagesIds: [9], isTableColumn: true },
    SELECTED_ALL_OBJECT: { id: "*", name: "All", isSelected: true },
    LAST_FILTERS_LOADED_TYPES: {
      ADVANCED_REPORT: "revenue",
      VISITOR_RECOGNITION: "recognition",
    },
    GROUP: { base: "group", title: "Group reports", pagesIds: [1, 2, 3], isTableColumn: false },
  },
  filtersKeysUnderscore: {
    actionTerminations: "action_termination",
    jsVersions: "js_version",
    pbVersions: "pb_version",
    sites: "vr_site",
    currencies: "currency",
    deviceTypes: "device_type",
    countries: "countryName",
    biddercodes: "biddercode",
    browsers: "browser",
    integrations: "integration",
  },

  ADVANCED_REPORT: {
    AREA_CHART_TYPES: {
      // REVENUE_LIFT: "revenueLift",
      // CPM_LIFT_RATE: "cpmLiftRate",
      // FILL_RATE_LIFT: "fillRateLift",

      REVENUE: {
        id: "revenue",
        name: "revenue",
        title: "Revenue",
        isSelected: true,
        bodyIcon: <CashIcon />,
        prefix: "$",
      },
      REVENUE_LIFT_NUM: {
        id: "revenueLiftNum",
        name: "revenue_lift_numeric",
        title: "Revenue Lift",
        isSelected: false,
        bodyIcon: <CashIcon />,
        prefix: "$",
      },
      REVENUE_LIFT_PER: {
        id: "revenueLiftPer",
        name: "revenue_lift_percent",
        title: "Revenue Lift",
        isSelected: false,
        bodyIcon: <PercentIcon />,
        prefix: "%",
      },
      CPM: {
        id: "cpm",
        name: "cpm",
        title: "CPM",
        isSelected: false,
        bodyIcon: <CashIcon />,
        prefix: "$",
      },
      CPM_LIFT_NUM: {
        id: "cpmLiftNum",
        name: "cpm_lift_numeric",
        title: "CMP lift",
        isSelected: false,
        bodyIcon: <CashIcon />,
        prefix: "$",
      },
      CPM_LIFT_PER: {
        id: "cpmLiftPer",
        name: "cpm_lift_percent",
        title: "CMP lift",
        isSelected: false,
        bodyIcon: <PercentIcon />,
        prefix: "%",
      },
      FILL_RATE: {
        id: "fillRate",
        name: "fill_rate",
        title: "Fill Rate",
        isSelected: false,
        bodyIcon: <PercentIcon />,
        prefix: "%",
      },
      IMPRESSIONS: {
        id: "impressions",
        name: "impressions",
        title: "Impressions",
        isSelected: false,
        bodyIcon: null,
        prefix: null,
      },
    },
    AGREGATION_DATA_CHART_TYPES: {
      SUMMARY: {
        id: "summary",
        name: "summary",
        title: "Summary",
        isSelected: false,
        bodyIcon: null,
        prefix: null,
      },
      PER_PARTNER: {
        id: "perPartner",
        name: "per_partner",
        title: "Per partner",
        isSelected: true,
        bodyIcon: null,
        prefix: null,
      },
    },
    TABLE_COLUMNS_FIELD_NAMES: {
      DATE: { field: "submitted_date", header: "Date", group: "Date" },
      PARTNERS: { field: "pName", header: "Partner", group: "Partner" },
      PCT: { field: "pct", header: "A/B %", group: "A/B %" },
      CURRENCIES: { field: "currency", header: "Currency", group: "Currency" },
      AB_REVENUE: { field: "abRevLift", header: "Lift", group: "A/B Revenue" },
      AB_REVENUE_PERCENT: { field: "abRevActualRateLift", header: "Lift %", group: "A/B Revenue" },
      REVENU_WITH_IIQ: { field: "total_cpm1", header: "With IIQ", group: "Revenue" },
      REVENUE_WITHOUT_IIQ: { field: "total_cpm2", header: "Without IIQ", group: "Revenue" },
      IMPRESSIONS_WITH_IIQ: { field: "total_rows1", header: "With IIQ", group: "Impressions" },
      IMPRESSIONS_WITHOUT_IIQ: { field: "total_rows2", header: "Without IIQ", group: "Impressions" },
      CPM_WITH_IIQ: { field: "avg_cpma", header: "With IIQ", group: "CPM" },
      CPM_WITHOUT_IIQ: { field: "avg_cpmb", header: "Without IIQ", group: "CPM" },
      CPM_LIFT: { field: "avgCpmRateLift", header: "Lift", group: "CPM" },
      FILL_RATE: { field: "abFillRateLift", header: "Fill Rate lift", group: "Fill Rate lift" },
      DEVICE_TYPES: { field: "dtName", header: "Device", group: "Device" },
      COUNTRIES: { field: "countryName", header: "Country", group: "Country" },
      BIDDERS: { field: "biddercode", header: "Biddercode", group: "Biddercode" },
      BROWSERS: { field: "client_type", header: "Browser", group: "Browser" },
      SITES: { field: "site_url", header: "Site", group: "Site" },
      JS_VERSIONS: { field: "js_version", header: "JS Version", group: "JS Version" },
      PB_VERSIONS: { field: "pb_version", header: "Prebid", group: "Prebid Version" },
      ACTION_TERMINATIONS: { field: "atc_name", header: "Termination cause", group: "Termination cause" },
      INTEGRATIONS: { field: "integration", header: "Integration", group: "Integration" },
    },
  },

  REPORTER_TYPES: {
    IIQ_SSDK: 1,
    GAM: 2,
  },

  FILTER_GROUP_TYPE: {
    FILTER: "filter",
    PARTNER: "partner",
  },

  REPORTER_TYPES: {
    IIQ_SSDK: 1,
    GAM: 2,
  },
  ACCOUNT_STATUS: {
    NOT_ACTIVE: { value: 0, label: "Inactive" },
    ACTIVE: { value: 1, label: "Active" },
    TRIAL: { value: 2, label: "Trial" },
  },

  selectAllGroupId: 9999999,
  clearAllGroupId: 9999999,

  cacheNameAccountImages: "account-images",
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
    organization: process.env.REACT_APP_AUTH0_ORGANIZATION_ID || "",
    clientId: process.env.REACT_APP_AUTH0_BE_APP_CLIENT_ID || "",
    audience: process.env.REACT_APP_AUTH0_NESTJS_API_AUDIENCE || "",
    redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URL || "",
    logout_uri: process.env.REACT_APP_AUTH0_LOGOUT_URL || "",
    maintenence_uri: process.env.REACT_APP_AUTH0_MAINTENANCE_URL || "",
    autotestClientId: process.env.REACT_APP_AUTH0_BE_AUTOTEST_CLIENT_ID || "",
    autotestClientSecret: process.env.REACT_APP_AUTH0_AUTOTEST_APP_CLIENT_SECRET || "",
  },
  MAX_FILE_SIZE: 1572864, // bytes - 1.5mb
  ALLOWED_FILE_IMAGE_TYPES: ["image/jpeg", "image/png", "image/jpg", "image/svg+xml"],
  MODULE_CONFIGURATIONS: {
    DefaultPartnersUser: {
      id: 1,
      name: "Default partners user",
      should_hide_sensitive_data: false,
      accept_user_override: false,
      accept_parent_override: false,
    },
    DefaultPartnersAccount: {
      id: 2,
      name: "Default Partners Account",
      should_hide_sensitive_data: false,
      accept_user_override: true,
      accept_parent_override: true,
    },
    DefaultPartnersPartner: {
      id: 3,
      name: "Default Partners Partner",
      should_hide_sensitive_data: false,
      accept_user_override: true,
      accept_parent_override: true,
    },
    IIQAccountManager: {
      id: 4,
      name: "IIQ account manager",
      should_hide_sensitive_data: false,
      accept_user_override: false,
      accept_parent_override: false,
    },
    IIQUser: {
      id: 5,
      name: "IIQ User (Hide sensetive data)",
      should_hide_sensitive_data: true,
      accept_user_override: false,
      accept_parent_override: false,
    },
    IIQAccountManagerLimit: {
      id: 8,
      name: "IIQ account manager (Hide sensetive data)",
      should_hide_sensitive_data: true,
      accept_user_override: false,
      accept_parent_override: false,
    },
    IIQConfigManager: {
      id: 9,
      name: "IIQ configuration manager",
      should_hide_sensitive_data: false,
      accept_user_override: false,
      accept_parent_override: false,
    },
  },
};

export const getCurrentPageIdByLocationPathname = (pathname) => {
  const page = Object.values(CONSTANT.PAGES).find((page) => page.path === pathname);
  return page ? page.id : null;
};
//TODO add env variable "https://login.intentiq.com"
